// Generated by Framer (89417f6)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./HQbQabdWL-0.js";

const cycleOrder = ["oLY06CO6_", "LlbIub0w0"];

const variantClassNames = {LlbIub0w0: "framer-v-1c4peip", oLY06CO6_: "framer-v-1kys27y"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}, LlbIub0w0: {damping: 60, delay: 0, mass: 1, stiffness: 200, type: "spring"}};

const valuesByLocaleId = {NTHK784vL: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transformTemplate = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "oLY06CO6_", "Variant 2": "LlbIub0w0"}

const getProps = ({height, id, title, width, ...props}) => { return {...props, MKgk8Klnn: title ?? props.MKgk8Klnn ?? "Čo hovoria klienti", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "oLY06CO6_"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, MKgk8Klnn, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "oLY06CO6_", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter1qr1o8m = activeVariantCallback(async (...args) => {
setVariant("LlbIub0w0")
})

const onMouseLeave2xltqp = activeVariantCallback(async (...args) => {
setVariant("oLY06CO6_")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-czAYD", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link {...addPropertyOverrides({LlbIub0w0: {href: {webPageId: "j2j9RqbVk"}}}, baseVariant, gestureVariant)}><motion.a {...restProps} className={`${cx("framer-1kys27y", className)} framer-1ghz2di`} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"oLY06CO6_"} onMouseEnter={onMouseEnter1qr1o8m} ref={ref} style={{...style}} {...addPropertyOverrides({LlbIub0w0: {"data-framer-name": "Variant 2", onMouseLeave: onMouseLeave2xltqp}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SW50ZXItQm9sZA==", "--framer-font-family": "\"Inter-Bold\", \"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "20px", "--framer-font-weight": "700", "--framer-text-alignment": "center"}}>Čo hovoria klienti</motion.p></React.Fragment>} className={"framer-syaxh6"} fonts={["Inter-Bold"]} layoutDependency={layoutDependency} layoutId={"KAW9zketx"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={MKgk8Klnn} transformTemplate={transformTemplate} verticalAlignment={"center"} withExternalLayout {...addPropertyOverrides({LlbIub0w0: {children: <React.Fragment><motion.p style={{"--font-selector": "SW50ZXItQm9sZA==", "--framer-font-family": "\"Inter-Bold\", \"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "22px", "--framer-font-weight": "700", "--framer-text-alignment": "center"}}>Čo hovoria klienti</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-czAYD [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-czAYD .framer-1ghz2di { display: block; }", ".framer-czAYD .framer-1kys27y { height: 53px; overflow: hidden; position: relative; width: 231px; }", ".framer-czAYD .framer-syaxh6 { aspect-ratio: 4.32258064516129 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 53px); left: 50%; position: absolute; top: 50%; white-space: pre-wrap; width: 231px; word-break: break-word; word-wrap: break-word; }", ".framer-czAYD.framer-v-1c4peip .framer-1kys27y { text-decoration: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 53
 * @framerIntrinsicWidth 231
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"LlbIub0w0":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"MKgk8Klnn":"title"}
 * @framerImmutableVariables false
 */
const FramerHQbQabdWL: React.ComponentType<Props> = withCSS(Component, css, "framer-czAYD") as typeof Component;
export default FramerHQbQabdWL;

FramerHQbQabdWL.displayName = "co hovoria klienti c";

FramerHQbQabdWL.defaultProps = {height: 53, width: 231};

addPropertyControls(FramerHQbQabdWL, {variant: {options: ["oLY06CO6_", "LlbIub0w0"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, MKgk8Klnn: {defaultValue: "Čo hovoria klienti", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerHQbQabdWL, [])